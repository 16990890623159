.header{
    display: flex;
    padding: 12.5px 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid #E2E8F0;
}

.tohomepage-container{
    display: flex;
    align-items: center;
    color: #64748B;
    gap: 8px;
    cursor: pointer;
}


.advanced-search{
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.apps-explorer{
    background: #FFF;
    border-radius: 12px;
    display: flex;
    width: 520px;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #E2E8F0;
    box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.10), 0px 20px 25px -5px rgba(0, 0, 0, 0.10);
    min-height: 320px;
}

.advanced-search-title{
    color: #1E293B;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    align-self: stretch;
}


.apps-search-container{
    display: flex;
    gap: 8px;
    width: 100%;
}


.advanced-search-resource{
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: none!important;
    border-radius: 0!important;
    border: none!important;
    top: 0;
    padding: 0!important;
    width: 100%;

}

