.tab-wizard-container {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    /*margin-top: 5px;*/
    /*display: flex;*/
    /*flex-direction: column;*/
}
.data-form-fields {
    padding: 15px;
    min-width: 200px;
    max-width: 300px;
    max-height: 65vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 10px;
}
.data-form-field-item {
    display: flex;
    justify-content: space-between;
    gap: 9px;
}
.buttons-data-edit{
    display: flex;
    gap: 2px;
}
.buttons-data-edit span{
    cursor: pointer;
}
.data-info-fields-container{
    grid-column: span 2;

}
.data-info-container{
    width: 100%;
    /*width: 100%;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    padding: 20px;
}
.editable-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form-container-wizard{
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.breadcrumb-wizard {
    font-size: 13px;
    width: 65%;
    color: #64748B;
    font-weight: 400;
    line-height: 19px;
    font-family: Inter ;
    padding:10px 0 10px 0;
    border-bottom: 1px solid rgb(221, 221, 221);
}

.separator-breadcrumb-wizard {
    margin: 0 5px;
}


.select-style{
        border-radius: 8px;
        border: 1px solid var(--slate-200);
        display: flex;
        align-items: center;
        align-self: stretch;
        padding: 10.5px 12px;

}
