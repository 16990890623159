.popup-field {
    margin: 1px 5px;
    padding: 5px;
    display: flex;

}

.popup-field-label{
    padding: 5px;
    flex: 0 0 120px;
    color: var(--fg-color-3);
    font-weight: 400;
}


.popup-field > input,
.popup-field > textarea
 {
    padding: 8px 10px;
    min-width: 100px;
    flex: 1 1;
    margin-bottom: 5px;
    border: 0.5px solid #E5E7EB;
    border-radius: 6px;
    background: #f9fafb;


    color: var(--hg-color-1); 
    font-weight: 300;
    
    font-size: 12px;

}


.create-app-modal-body{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px;
}


.create-app-modal-body .text-md{
    font-size:12px;
}

.create-app-modal-body input::placeholder, .create-app-modal-body textarea::placeholder{
    font-size:12px;
    font-weight: lighter;
}
.create-app-modal-body textarea::-webkit-resizer{
    width: 9px;
    height: 9px;
    color: #94A3B8;

}

.create-app-wisard-modal-description{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.create-app-modal-body{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px;
}

.id-directory{
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #64748B;
}

.name-directory{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.recap-info-container{
    display: flex;
    flex-direction: column;
    border-top: 2px solid #E5E8EB;
    padding: 14px;
}
.recap-info-title{
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: #637587;
    line-height: 21px;

}


.custom-counter {
    list-style-type: none;
  }
  
  .custom-counter li {
    counter-increment: step-counter;
    margin-bottom: 5px;
    color: #ADB5BD;
    font-weight: 500;
    padding: 10px 72px 10px 0px;
  }
  
  .custom-counter li::before {
    content: counter(step-counter);
    margin-right: 6px;
    font-size: 80%;
    color: #94A3B8; /* Default number color */
    background-color: #EFF6FF; /* Default background color */
    border: 1px solid #CBD5E1; /* Default border color */
    font-weight: bold;
    padding: 3px 8px;
    border-radius: 6px;
  }
  
  .custom-counter li.tab-active {
    border-right: 2px solid #2563EB; /* Active border color */
    color: #1E293B;
  }
  
  .custom-counter li.tab-active::before {
    color: #2563EB; /* Active number color */
    background-color: #EFF6FF; /* Active background color */
    border-color: #2563EB; /* Active border color */
  }
  















  
.empty-result-container{
    display: flex;
    padding: 60px 60px 80px 60px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    justify-content: center;
}

.empty-result-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.empty-result-message-top{
    color: #1E293B;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}

.empty-result-message-bottom{
    color: #64748B;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
}

.list-actions-deliver .list-options-item.light:first-child{
    color: #B91C1C;
}








.login-error-message.warning span {
    text-decoration: underline;
    margin-left: 5px;
}
.login-error-message.warning {
    background-color: #FEF2F2;
    border: 1px solid #FCA5A5;
}
.login-error-message {
    display: flex;
    margin-top: 1.5rem;
    font-family: 'Inter';
    color: #334155;
    font-weight: 400;
    font-size: 11px;
    background: #FEF2F2;
    padding: 8px 16px 8px 16px;
    text-decoration: none;
    width: 100%;
    border: 1px solid #FCA5A5;
    border-radius: 4px;
    line-height: 16px;
}