.settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8%;
    padding-right: 8%;
    margin: 10px;
}
.settings-title{
    font-size: 17px;
    font-weight: 500;
    font-family: 'Inter';
}