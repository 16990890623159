.spinner {
    position: relative;
    animation: rotate 1.5s linear infinite;
}
  
.rotating-border {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #E2E8F0;
    border-top-color: #2563EB;
}
  
@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.spinner-container{
    position: relative;
    width: fit-content;
    height: fit-content;
}

.spinner-container img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 36px;
    height: 64px;
}