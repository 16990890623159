.tab-container{
    display: flex;
    /* padding: 20px 80px 80px 80px; */   
    /* Please if u want to add a style for an element try to not impact other elements having same selector (check if there are other element with the same class..., or add your specific class or a div as a parent element that will help u to disting the element that u want to style ) */
    justify-content: center
}
.organisation-component .container-head {
    width: 100% !important;
}

.side-navbar{
    width: 240px;
}
.settings-modules .side-navbar .tabs-kls-vertical{
    border-radius: 8px;
}
.settings-content{
    margin: 5px;
    /* width: -moz-fit-content; */
    width: fit-content;
    padding: 24px;
    border: 0.5px solid #D1D5DB;
    border-radius: 8px;
    width: 75%;
}

.oragnisation-topic{
    border-bottom: 1px solid #E2E8F0;
    padding: 0 0 18px 0;
}

.oragnisation-subtopic{
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.oragnisation-topic-head{
    font-family: "Inter";
    font-weight: 500;
    font-size: 15px;
    color: #1E293B;
    margin: 0;
}

.oragnisation-topic-para{
    color: #64748B;
    font-family: "Inter";
    font-weight: 500;
    font-size: 12px;
    margin: 0;
}

.login-logo-organization{
    margin-bottom: 0px !important;
    width: 80px;
    height: 80px;
    object-fit: contain;
}
.error-message{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#FEF2F2 ;
    border: #FCA5A5 1px solid;
    box-shadow: 0px 1px 2px 0px #0000000D;
    gap: 6px;
    padding: 8px 12px;
    border-radius: 4px;
}

.organisation-imgSec-main{
    display: flex;
    margin: 2rem 0; 
    gap: 32px;
    flex-direction: column;
}
.error-logo-org{
    color: #DC2626;
}

.orgination-error-message{
    font-family: "Inter";
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    color: #1E293B;
}
.organisation-imgSec{
    display: flex;
    /* margin: 2rem 0; */
    align-items: center;
}

.oragnisation-logo{
    align-items: center;
    text-align: center;
    width: 80px;
    padding: 10px;
    border-radius: 100px;
    border: 1px solid #E2E8F0;
    height: 80px;
    display: flex;
    justify-content: center;
}

.Imgrestriction-Organisation{
    margin-left: 2rem;
}

.Img-title{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
    color: #334155;
    margin-top: 8px;
}

.Img-title span{
    color: #2563EB;
}

.organisation-form-head{
    color: #1D4ED8;
    margin: 0;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 13px;
}

.organisation-personal-detials ,.organisation-transport-detials{
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.organisation-name-detials{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 32%;
}
.organisation-subname-detials ,.oragnisation-industryList{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 32%;
}

.oragnisation-industryList input::placeholder{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    color: #94a3b8;
}

.organisation-owner-detials ,.organisation-car-color{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 32%;
}
.organisation-name-detials h1,.organisation-subname-detials h1,.oragnisation-industryList h1 ,.organisation-car-color h1{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    color: #1E293B;
    margin: 0;
}

.organisation-name-detials input::placeholder,.organisation-subname-detials input::placeholder,.organisation-car-color input::placeholder{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    color: #94a3b8;
}

/* .organisation-name-detials input{
    border: 1px #E2E8F0 solid;
    padding: 10.5px 12px;
    border-radius: 8px;
} */

.organisation-name-detials p{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    color: #64748B;
    margin: 0;
}

.organisation-owner-detials h1{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    color: #1E293B;
    margin: 0;
}
/*  */
.organisation-owner-detials input {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #1E293B;
    background-color: #F8FAFC;
}
  
.organisation-form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid #E2E8F0;
    padding: 0 0 34px 0;
}

.organisation-dropdown
    {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 280px;
    }

.organisation-dropdown h1{
        font-family: 'Inter';
        font-weight: 500;
        font-size: 13px;
        color: #1E293B;
        margin: 0;
    }

.organisation-color{
    display: flex;
    flex-direction: column;
    width:280px ;
    gap: 4px;
}

.organisation-color h1{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    color: #1E293B;
    margin: 0;
}

.organisation-transportation{
    display: flex;
    gap: 12px 
}

.organisation-description{
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: 124px;
}

.organisation-description h1{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    color: #1E293B;
    margin: 0;
}

.organisation-description textarea::placeholder{
    color: #94A3B8;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
}

.organisation-description textarea{
    width: 100%;
}

.organisation-tags{
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: 64px;
}

.organisation-tags h1{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    color: #1E293B;
    margin: 0;
}

.organisation-tags-input::placeholder{
    color: #94A3B8;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
}
.organisation-tags-input{
    background-color: #ffff;
    width: 100%;
}
.org-icon-input input{
    border: 1px #E2E8F0 solid !important ;
    border-radius: 8px !important;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
}
  
.organisation-contact-head{
    color: #1D4ED8;
    margin: 0;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 13px;
}

.organisation-Contact{
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 16px;  
    border-bottom: 1px solid #E2E8F0;  
}

.organisation-contact-detials{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.organisation-contact-firstdiv{
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.organisation-contact-seconddiv{
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.organisation-email-detials{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 46%;
}
.organisation-email-detials input {
    width: 100%;
}

.organisation-email-detials h1{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    color: #1E293B;
    margin: 0;
}
.organisation-email-detials input::placeholder{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    color: #94a3b8;
}


.organisation-number-detials{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 46%;
}
.organisation-number-detials input {
    width: 100%;
}

.organisation-number-detials h1{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    color: #1E293B;
    margin: 0;
}
.organisation-number-detials input::placeholder{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    color: #94a3b8;
}

.organisation-web-detials{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 46%;
}
.organisation-web-detials input {
    width: 100%;
}

.organisation-web-detials h1{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    color: #1E293B;
    margin: 0;
}
.organisation-web-detials input::placeholder{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    color: #94a3b8;
}

.organisation-identification-detials{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 46%;
}
.organisation-identification-detials input {
    width: 100%;
}

.organisation-identification-detials h1{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    color: #1E293B;
    margin: 0;
}
.organisation-identification-detials input::placeholder{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    color: #94a3b8;
}

.organisation-address{
    margin: 2rem 0;
    border: 1px #E2E8F0 solid;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 8px;
}

.organisation-address-header{
    display: flex;
    gap: 24px;
    padding: 8px 8px 20px 8px;
    border-bottom: 1px solid #E2E8F0
}
.organisation-address-topic{
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.organisation-address-topic h1{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 15px;
    color: #1E293B;
    margin: 0;
}

.organisation-address-topic p{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    color: #64748B;
    margin: 0;
}

.organisation-address-primary{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 25%;
    gap: 4px;
}
.org-primary-icon{
    color: #2563EB;
}
.organisation-address-primary h1{
    color: #2563EB;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    margin: 0;
}

/*  */
.organisation-search{
    margin: 14px 0;
}

.iconsSection-org {
    position: relative;
    width: fit-content;
    bottom: 33px;
    float: right;
    right: 16px;
}

img.clearSearch {
    padding-right: 12px;
}

.organisation-table table{
    border-radius: 8px !important;
}
.organisation-table table thead tr:first-child{
    width:49px
}

.organisation-table th:first-child {
    width:49px
}

.organisation-button button{
    background-color: #3B82F6;
}

.organisation-button button span{
    color: #ffff;
}
.organisation-button{
    display: flex;
    justify-content: flex-end;
}

.org-edit-icon{
    display: none;
}

.organisation-logo:hover .login-logo-organization {
    display: none;
  }
  
.organisation-logo:hover .org-edit-icon {
    display: block; 
    color: #475569;
    text-align: center;
    background-color: #E2E8F0;
    margin-bottom: 0px !important;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: #94A3B8 solid 1px;
    box-shadow: inset 0px 0px 14px 0px #94A3B8;
    cursor: pointer;    
}

.new-edit-logo-org{
    display: flex;
    width: 100%;
    height: 100%;
    color: #475569;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.file-upload {
    display: none; 
}

.dropdownErr div .dropdown_br-md__ZFTuP {
    background-color: #FEF2F2 !important;
    border: #DC2626 1px solid !important;
    outline: none !important;
}

.organisation-settings-address-button{
    height: 40px;
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    color: #2563EB;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
}

.organisation-table{
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.organisation-table{
    margin-bottom: 16px;
}

.oragnisationTableEditor-main-div{
    width: 600px;
    background-color: #1D4ED8;
}

/*  */


.organisation-table-Modal-navbar {
    display: flex;
    border-bottom: #E2E8F0 1px solid;
  }

.organisation-table-Modal-navbar h1{
    color: #64748B;
    /* border-bottom: #E2E8F0 1px solid; */
    font-weight: 400;
    font-family: 'Inter';
    font-size: 13px;
    text-align: center;
    margin: 0;
    padding: 10.5px 8px 10.5px 8px;

}
  
  .nav-item {
    flex: 1;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  
 .active h1{
    color: #2563EB !important;
    border-bottom: 2px #2563EB solid;
    font-weight: 500;
    font-family: 'Inter';
    font-size: 13px;
    text-align: center;
  }

.Organisation-editor-TabContent1{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px 32px 16px;
}

.Organisation-editor-Tabcontent1-dropdown{
    display: flex;
    gap: 16px;
    width: 100%;
}

.organisation-table-Modal-Button{
    padding: 12px 16px 12px 16px;
    display: flex;
    justify-content: space-between;
    background-color: #F8FAFC;
    border-top: 1px solid #E2E8F0
}

.organistaion-cancel-button-address-model{
    background-color: #ffff;
    box-shadow: 0px 2px 3px 0px #0000000D;
    border: 1px solid #E2E8F0;
    padding: 10.5px 12.5px;
    border-radius: 8px ;
}

.organistaion-cancel-button-address-model span {
    color: #1E293B;
    font-weight: 500;
    font-family: 'Inter';
    font-size: 13px;
    text-align: center;
}

.Organisation-editor-TabContent2-CreationDate h1, .Organisation-editor-TabContent2-ModificationDate h1{
    color: #1E293B;
    font-weight: 500;
    font-family: 'Inter';
    font-size: 13px;   
}
.Organisation-editor-TabContent2-CreationDate{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Organisation-editor-TabContent2-ModificationDate{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Organisation-editor-TabContent2-CreationDate p, .Organisation-editor-TabContent2-ModificationDate p{
    color: #64748B;
    font-weight: 500;
    font-family: 'Inter';
    font-size: 13px;
}

.Organisation-editor-TabContent2{
    display: flex;
    gap: 24px;
    flex-direction: column;
    padding: 24px 16px 24px 16px;
}

.organisation-table-Modal-Addform .modal-title{
    font-family: 'Inter';
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
}
.organisation-table-Modal-Editform .modal-title{
    font-family: 'Inter';
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
}

.organisation-table-Modal-navbar .tab-pull-right{
   flex: none;
}

.Organisation-editor-Tabcontent1-Localitydropdown ,.Organisation-editor-Tabcontent1-Paysdropdown{
    width: 50%;
}