


.text-xs{
color: var(--slate-800, #1E293B);
font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 145.455% */
}

.text-sm {

    color: var(--slate-800, #1E293B);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px; 


}

.text-rg{
    color: var(--slate-800, #1E293B);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}

.text-md{
    color: var(--slate-800, #1E293B);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}

.text-lg{
    color: var(--slate-800, #1E293B);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
}

.text-xl{
    color: var(--slate-800, #1E293B);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.font-500{

    font-weight: 500;

}

.font-600{

    font-weight: 600;

}

.font-700{
    font-weight: 700;
}
