
.domain-container{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.domain-top-content{
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-bottom: 1px solid #E2E8F0;
}

.domain-header{
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 66px;
}

.err_mes-dom{
    display: flex;
    border: 1px solid #FCA5A5;
    background-color: #FEF2F2;
    padding: 8px 16px;
    border-radius: 4px;
}

.d-t1{
    font-family: "Inter";
    font-size: 15px;
    font-weight: 500;
    margin: 0;
}

.d-t2{
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    color: #64748B;
}

.input-placeholder-domain input::placeholder{
    color: #94A3B8 ;
}

.domain-button button{
    background-color: #3B82F6 !important;
}

.domain-button button span{
    color: #ffff !important;
}
.domain-button{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.dom-err-content{
    margin: 0;
}