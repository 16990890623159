.Scontainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
}

.Scircle {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Scircle .Snumber {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border:1px solid #475569;
    background-color: #64748B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Slabel {
    color: darkgray;
    text-align: center;
    margin-top: 5px;
}

.Sarrow {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #475569;
    margin-bottom: 20px;
}

.optionz {
    display: flex;
    align-items: center;
    margin-left: auto; 
}

.optionz > div {
    display: flex;
    align-items: center;
    margin-right: 10px; 
}

.radio-container {
    display: block;
    flex-direction: column;
    margin: auto;
}

.radio-container input[type="radio"] {
    margin-bottom: 5px; 
}

.radio-container label {
    margin-left: 5px;
}

.radio-container input[type="radio"]:checked + label {
    font-weight: bold; 
}

.ow-vl.ow-vbox.vboxcolumns {
    height: 100% !important;
}

.ow-form-body{
    height: fit-content !important;
    padding: 10px !important;
}
