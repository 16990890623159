.course-details{
  background-color: #F9FAFB;
  border-top: 0.5px solid #D1D5DB
}


.course-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.course-cards .title {
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E293B;
}

.title{
  margin: 14px 0;
}

.course-cards .sub-head {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #2563EB;
}

.course-section {
    /* flex-wrap: wrap; */
    display: flex;
    gap: 40px;
    margin-top: 2rem;
}

@media screen and (min-width:800px){
  .course-details {
      padding: 2.5rem 6rem;
  }
}


/* Card scroll */

@media screen and (max-width: 1320px) {
    .course-section {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 20px; /* Optional: Adjust as needed */
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer and Edge */
    }
  
    .course-section::-webkit-scrollbar {
      display: none; /* WebKit (Chrome, Safari, etc.) */
    }
  }

  @media screen and (max-width: 800px){
    .course-details{
      padding:2.5rem 40px;

    }
  }
  @media screen and (max-width: 400px){
    .course-details{
      padding:2.5rem 30px;
    }
    .course-section{
      gap: 30px !important;
    }
  
  }



  
