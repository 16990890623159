.main-tab-container{
    display: flex;
    width: 80vw;

}
.settings-container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.theme-container{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.side-navbar > .tabs-kls-vertical > .tab{
    padding: 6px 10px 6px 10px;
    gap: 6px;
}


.theme-title2{
    margin: 0px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
}

.theme-mode{
    height: 175px;
    border-bottom: 1px solid #E2E8F0;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.theme-mode-color{
    display: flex;
    gap: 28px;
}

.auth-settings-apperance-div{
    position: relative;
}

.mode-img{
    width: 226px;
    height: 112px;
}

.auth-settings-apperance-type {
    font-family: 'Inter';
    text-align: center;
    font-size: 9px;
    position: absolute;
    width: 35.64px;
    height: 14px;
    top: 7px;
    left: 8px;
    border-radius: 3px;
    z-index: 10001;
    margin: 0;
    background: white;
}

.somber-type{
    width: 48px;
}

.system-type{
    width: 54px;
}

.auth-settings-apperance-selectTheme{
    box-sizing: border-box;
    padding: 0px;
    position: absolute;
    top: 91px;
    left: 13px;
}

.auth-settings-apperance-colorThemeContainer{
    display: flex;
    gap: 32px;
    height: 59.11px;
}

.auth-settings-apperance-colorTheme{
    display: grid;
    flex-direction: column;
    gap: 12px;
    width: 430px;
}

.auth-settings-apperance-colorTitle{
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    margin: 0;
}

.auth-settings-apperance-colorThemeRadio{
    display: flex;
    gap: 9.37px;
}

.auth-settings-apperance-dateContainer{
    width: 430px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.auth-settings-apperance-dateChangeFont{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}

.auth-settings-apperance-dateRatio{
    display: flex;
    gap: 24px;
}

.auth-settings-apperance-dataRadio1{
    display: flex;
    gap: 4px;
    align-items: center;
    width: 20%;
}

.color-theme-radio .input-container {
    width: auto !important; 
}

.color-theme-radio .input-container div{
   padding: 0 !important;
   border: none;
   box-shadow: none !important;

}

.color-theme-radio .app-settings-apperance-radio{
    height: 30px;
    width: 30px;
    padding: 0 !important;
    box-shadow: none;
}

.app-appearance-colorPicker{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.appearance-color1{
    background-color: #B27FF9;
}

.appearance-color2{
    background-color: #2563EB;
}

.appearance-color3{
    background-color: #27C177;
}

.appearance-color4{
    background-color: #EFCD70;
}

.appearance-color5{
    background-color: #F88149;
}

.appearance-color6{
    background-color: #F764A4;
}

.appearance-color7{
    background-color: #FF6868;
}

.selected-color-theme{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
}

.appearance-purple{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #B27FF9;
}
.appearance-blue{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #2563EB;
}

.appearance-green{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #27C177;
}

.appearance-sandal{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #EFCD70;
}

.appearance-orange{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #F88149;
}

.appearance-pink{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #F764A4;
}

.appearance-red{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #FF6868;
}

.appearance-colorpicker {
    width: 20.5px;
    height: 20px;
    border-radius: 50%;
}

.app-appearance-btn{
    display: flex;
    justify-content: flex-end;
}

.auth-settings-apperance-dataRadio1 .input-element{
    padding: 0 !important;
    border: none !important;
}

/* .auth-settings-apperance-dataRadio1 input{
    width: 0 !important;
} */

.auth-settings-apperance-dataRadio1 .input-container{
    width: auto !important;
}

.auth-settings-apperance-type .input-element{
    padding: 0 !important;
    border: none !important;
}