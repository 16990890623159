header{
    display : flex!important;
    background-color : #020617;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: Inter;
}

.header-elements{
    display: flex;
    align-items: center;
    gap: 16px;
    height: 40px;
    width: 100%;
}
header .left-side-header{
    display: flex !important;
}

header .left-side-header a:focus {
    outline: 1.6px solid #3B82F6;
    outline-offset: 1.6px;
    box-shadow: 0 0 0 1.6px #fff;
}

header .left-side-header a {
    display: flex;
    align-items: center;
}

header .nav-module{
    display: flex;
    align-items: center;
    margin: auto 6px;
    width: 145px;
}

header .block-header{
    background-color : #1E293B;
    border-radius: 8px;
    border: 1px solid #334155;
    color: #fff;
    box-sizing: border-box;    
}


header .icon-container-nav-module{
    display: flex;
    align-items: center;
}

header .block-parameters{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 12px;
}

header .block-parameters .material-icon{
    color: #fff;
}

header .menu-container{
    display: flex;
    gap: 8px;
}

.header-module{
    color: #fff;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.label-module{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.header-model{
    display: flex;
    align-items: center;
    gap: 4px;
    color: #CBD5E1;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    max-width: 100%;
}
