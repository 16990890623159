.publication-content{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.hlabel{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    gap: 8px;
}

.label-hlabel{
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    color: var(--slate-800);
}

.label-hlabel span{
    color: #1E293B;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}


.important-color .label{
    color: #B91C1C;
}




.all-models-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.all-models-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-height: 130px;
    width: 100%;
}

.models-pagination-container{
    width:100%;
    display:flex;
    justify-content:center;
}

.loading-models-pub{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.publication-content.step2{
    align-items: center;
}



.tag-info{
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #64748B;
}

.status-info{
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    box-shadow: 0px 1px 2px 0px #0000000D;
    border: 1px solid #E2E8F0;
    padding: 4px 8px 4px 8px;
    border-radius: 100px;
    border: 1px;
    gap: 6px;
}
.status-info>*:last-child{
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E293B;

}

.status-info>*:first-child{
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #10B981;
}



