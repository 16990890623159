

/* FILEPATH: /c:/Users/SAAD/Projects/StoryBook/kls-storybook/src/colors.css */

:root {
    --white-base: #ffffff;
    --emerald-500: #10B981;
    --emerald-600: #059669;
    --emerald-700: #047857;
    --emerald-800: #065F46;
    --orange-500: #F97316;
    --red-50: hsl(0, 86%, 97%);
    --red-500:#EF4444;
    --red-600: #DC2626;
    --red-700: #B91C1C;
    --red-800: #991B1B;

    --gray-200: #E5E7EB;
    --slate-50: #F8FAFC;
    --slate-100: #F1F5F9;
    --slate-200: #E2E8F0;
    --slate-300: #CBD5E1;
    --slate-400: #94A3B8;
    --slate-500: #64748B;
    --slate-600: #475569;
    --slate-700: #334155;
    --slate-800: #1E293B;
    --slate-900: #0F172A;
    --slate-950: #020617;
    --blue-50: #EFF6FF;
    --blue-500: #3B82F6;
    --blue-600: #2563EB;
    --blue-700: #1D4ED8;
    --blue-800: #1E40AF;
    --blue-900: #60A5FA;
}

.white-base{
color:var(--white-base);
}
.emerald-500{
color:var(--emerald-500);
}
.emerald-600{
color:var(--emerald-600);
}
.emerald-700{
color:var(--emerald-700);
}
.emerald-800{
color:var(--emerald-800);
}
.orange-500{
color:var(--orange-500);
}
.red-50{
color:var(--red-50);
}
.red-500{
color:var(--red-500);
}
.red-600{
color:var(--red-600);
}
.red-700{
color:var(--red-700);
}
.red-800{
color:var(--red-800);
}
.slate-50{
color:var(--slate-50);
}
.slate-100{
color:var(--slate-100);
}
.slate-200{
color:var(--slate-200);
}
.slate-300{
color:var(--slate-300);
}
.slate-400{
color:var(--slate-400);
}
.slate-500{
color:var(--slate-500);
}
.slate-700{
color:var(--slate-700);
}
.slate-800{
color:var(--slate-800);
}
.slate-900{
color:var(--slate-900);
}
.slate-950{
color:var(--slate-950);
}
.blue-50{
color:var(--blue-50);
}
.blue-500{
color:var(--blue-500);
}
.blue-600{
color:var(--blue-600);
}
.blue-700{
color:var(--blue-700);
}
.blue-800{
color:var(--blue-800);
}
.blue-900{
color:var(--blue-900);
}
.gray-200{
    color:var(--gray-200);
}



.bg-white-base{
background-color:var(--white-base);
}
.bg-emerald-500{
    background-color:var(--emerald-500);
    }
.bg-emerald-600{
background-color:var(--emerald-600);
}
.bg-emerald-700{
background-color:var(--emerald-700);
}
.bg-emerald-800{
background-color:var(--emerald-800);
}
.bg-orange-500{
background-color: var(--orange-500);
}
.bg-red-50{
background-color:var(--red-50);
}
.bg-red-500{
background-color:var(--red-500);
}
.bg-red-600{
background-color:var(--red-600);
}
.bg-red-700{
background-color:var(--red-700);
}
.bg-red-800{
background-color:var(--red-800);
}
.bg-gray-200{

    background-color:var(--gray-200);

}
.bg-slate-50{
background-color:var(--slate-50);
}
.bg-slate-100{
background-color:var(--slate-100);
}
.bg-slate-200{
background-color:var(--slate-200);
}
.bg-slate-300{
background-color:var(--slate-300);
}
.bg-slate-400{
background-color:var(--slate-400);
}
.bg-slate-500{
background-color:var(--slate-500);
}
.bg-slate-700{
background-color:var(--slate-700);
}
.bg-slate-800{
background-color:var(--slate-800);
}
.bg-slate-900{
background-color:var(--slate-900);
}
.bg-slate-950{
background-color:var(--slate-950);
}
.bg-blue-50{
background-color:var(--blue-50);
}
.bg-blue-500{
background-color:var(--blue-500);
}
.bg-blue-600{
background-color:var(--blue-600);
}
.bg-blue-700{
background-color:var(--blue-700);
}
.bg-blue-800{
background-color:var(--blue-800);
}
.bg-blue-900{
background-color:var(--blue-900);
}